<template>
  <div class="tabs-bar">
    <div class="tabs-header">
      <div
        v-for="item in tabItems"
        :key="item.identifier"
        @click="activateTab(item.identifier)"
        class="tab"
        :class="{'is-active': item.identifier === tabActiveId, [item.identifier]: true}"
      >
        <component v-if="item.icon" :is="item.icon" :size="20"></component>
        <div>{{ getItemName(item) }}</div>
      </div>
    </div>

    <div class="tabs-content stand-out">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ProfileIcon from 'vue-material-design-icons/Account';
import RelationshipIcon from 'vue-material-design-icons/HumanMaleFemaleChild';
import AssetsIcon from 'vue-material-design-icons/Image';
import LightbulbIcon from 'vue-material-design-icons/Lightbulb';

export default {
  props: {
    startTab: String,
  },
  data() {
    return {
      tabItems: {},
      tabActiveId: '',
      tabsContentRef: 'tabs-content',
    };
  },
  methods: {
    register(tabItemInstance) {
      if (this.isTabItemComponent(tabItemInstance.$options)) {
        this.tabItems = {
          ...this.tabItems,
          [tabItemInstance.identifier]: this.tabItems[tabItemInstance.identifier] || tabItemInstance,
        };
        if (!this.tabActiveId && (!this.startTab || this.startTab === tabItemInstance.identifier)) {
          this.activateTab(tabItemInstance.identifier);
        }
      }
    },
    isTabItemComponent(options) {
      return options && options.name === 'TabsBlockItem';
    },
    activateTab(identifier) {
      if (this.tabActiveId === identifier) {
        return;
      }
      const fromTabId = this.tabActiveId;
      this.tabActiveId = identifier;
      for (let tab of Object.values(this.tabItems)) {
        tab.setIsActive(tab.identifier === this.tabActiveId);
        if (fromTabId && tab.identifier === fromTabId) {
          tab.deactivate();
        }
      }
      this.$emit('activate-tab', {tabId: this.tabActiveId, fromTabId});
    },
    getItemName(item) {
      return item.name;
    },
    fullDeactivate() {
      for (let tab of Object.values(this.tabItems)) {
        if (this.tabActiveId && tab.identifier === this.tabActiveId) {
          tab.deactivate();
        }
      }
    },
  },
  components: {
    ProfileIcon,
    AssetsIcon,
    LightbulbIcon,
    RelationshipIcon,
  },
  name: 'TabsBar',
};
</script>

<style lang="scss" scoped>
.tabs-bar {
  .tabs-header {
    display: flex;
    overflow-x: auto;
    font-size: $text-size;
    line-height: $line-height;
    background: $background-light;

    &::v-deep {
      .tab {
        cursor: pointer;
        padding: 10px 12px;
        margin-right: 4px;
        white-space: nowrap;
        color: $neutral-400;
        display: flex;
        align-items: center;

        .material-design-icon {
          margin-right: 10px;
        }

        &.is-active {
          font-weight: 800;
          color: $text-color;
          box-shadow: inset 0 -2px 0 $primary-400;
        }
      }
    }
  }
}
</style>
