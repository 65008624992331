<template>
  <div class="compare-relatives-table">
    <div class="table-legend table-row text-md">
      <div class="table-cell label selectable" @click="allFieldsCheckboxChange">
        <div class="input-wrapper">
          <input type="checkbox" id="all_fields_input" v-model="allFieldsSelected" />
        </div>
      </div>
      <div class="values">
        <div class="table-cell">Select All</div>
      </div>
    </div>
    <div class="table-body table-row" v-for="item in relativesTable" :class="getTableRowClass(item)">
      <div class="table-cell label text-md" :class="{selectable: true}" @click="toggleCheckbox(item)">
        <div class="input-wrapper">
          <input v-if="true" type="checkbox" v-model="idsToSave" :id="item.id" :value="item.id" />
        </div>
      </div>
      <div class="values">
        <div class="table-cell content">
          <div class="cell-content-wrapper">
            <div class="inline">
              <span class="full-name">{{ item.full_name }}</span>
              <span class="text-sm relation">({{ item.relation }})</span>
              <span class="chip dark-green extra-small text-xs">New</span>
            </div>
            <div class="mother-multiselect" v-if="item.relation_category === 'children'">
              <single-option-select
                placeholder=""
                value-field="id"
                multiselect-classes="medium-size"
                :value="otherParentsByChildId[item.id]"
                :allow-remove="false"
                :options="possibleOtherParentsForChildren"
                :showLabels="false"
                @select="onOtherParentValueChange(item, $event)"
              ></single-option-select>
            </div>
            <div class="arrow" @click="toggleShowDetails(item)">
              <arrow-icon :size="20"></arrow-icon>
            </div>
          </div>

          <div v-if="showDetails[item.id]" class="details">
            <compare-relatives-table-person
              v-if="details[item.id]"
              :person="details[item.id]"
            ></compare-relatives-table-person>
            <mcr-loading-indicator :loading="true" v-else></mcr-loading-indicator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect';
import network from '@common/network/network';
import ArrowIcon from 'vue-material-design-icons/ChevronDown';

import CompareRelativesTablePerson from '@/components/common/tree/CompareRelativesTablePerson';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    relativesTable: Array,
    personRelatives: Array,
  },
  data() {
    return {
      idsToSave: [],
      allFieldsSelected: false,
      details: {},
      showDetails: {},
      otherParentsByChildId: {},
    };
  },
  created() {
    this.initOtherParentsByChildId();
  },
  computed: {
    relativesToSave() {
      return this.relativesTable.filter(relative => this.idsToSave.includes(relative.id));
    },
    possibleOtherParentsForChildren() {
      const personSpouses = this.personRelatives.filter(r => r.relation_category === 'spouses');
      const possibleSpouses = this.relativesToSave.filter(r => r.relation_category === 'spouses');
      const parents = [...personSpouses, ...possibleSpouses].map(s => ({name: s.full_name || UNKNOWN_NAME, id: s.id}));
      return [...parents, {id: null, name: 'Other Parent'}];
    },
  },
  methods: {
    initOtherParentsByChildId() {
      for (let person of this.relativesTable) {
        if (person.relation_category === 'children' && this.possibleOtherParentsForChildren.length) {
          this.otherParentsByChildId[person.id] = this.possibleOtherParentsForChildren[0].id;
        }
      }
    },
    allFieldsCheckboxChange() {
      const newValue = !this.allFieldsSelected;
      this.allFieldsSelected = newValue;
      for (let person of this.relativesTable) {
        if (newValue) {
          this.select(person);
        } else {
          this.deselect(person);
        }
      }
    },
    select(person) {
      if (
        person.relation_category === 'parents' &&
        this.personRelatives.some(pr => pr.relation_category === 'parents') &&
        this.relativesToSave.some(pr => pr.relation_category === 'parents')
      ) {
        this.$toasted.error('A person may have only two parents.');
        return;
      }
      if (
        person.relation_category === 'siblings' &&
        !this.personRelatives.some(pr => pr.relation_category === 'parents') &&
        !this.relativesToSave.some(pr => pr.relation_category === 'parents')
      ) {
        this.$toasted.error('A person should have parents to save siblings.');
        return;
      }
      this.idsToSave = [...this.idsToSave, person.id];
      if (person.relation_category === 'spouses') {
        Object.keys(this.otherParentsByChildId).forEach(childId => {
          if (!this.otherParentsByChildId[childId]) {
            this.$set(this.otherParentsByChildId, childId, person.id);
          }
        });
      }
    },
    deselect(person) {
      this.idsToSave = this.idsToSave.filter(personId => personId !== person.id);
      if (this.idsToSave.length === 0) {
        this.allFieldsSelected = false;
      }
      if (person.relation_category === 'spouses') {
        Object.keys(this.otherParentsByChildId).forEach(childId => {
          if (this.otherParentsByChildId[childId] === person.id) {
            this.$set(this.otherParentsByChildId, childId, null);
          }
        });
      }
    },
    toggleCheckbox(item) {
      if (this.idsToSave.includes(item.id)) {
        return this.deselect(item);
      }
      return this.select(item);
    },
    getTableRowClass(item) {
      return this.idsToSave.includes(item.id) ? 'selected' : '';
    },
    toggleShowDetails(item) {
      const newValue = !this.showDetails[item.id];
      this.showDetails = {...this.showDetails, [item.id]: newValue};
      if (newValue && !this.details[item.id]) {
        network.source.getPersonMentions({code: item.code}).then(response => {
          this.details = {...this.details, [item.id]: response};
        });
      }
    },
    getDataForSave() {
      let data = {};
      for (let person of this.relativesToSave) {
        data[person.relation_category] = data[person.relation_category] || [];
        let newItem = {person_id: person.id};
        if (this.otherParentsByChildId[person.id]) {
          newItem['parent_id'] = this.otherParentsByChildId[person.id];
        }
        data[person.relation_category].push(newItem);
      }
      return data;
    },
    onOtherParentValueChange(child, parentId) {
      this.otherParentsByChildId = {...this.otherParentsByChildId, [child.id]: parentId};
    },
  },
  components: {SingleOptionSelect, CompareRelativesTablePerson, ArrowIcon},
  name: 'CompareRelativesTable',
};
</script>

<style lang="scss" scoped>
.compare-relatives-table {
  border-radius: 4px;
  border: 1px solid $neutral-100;
  box-shadow: $slight-shadow-bottom;

  .table-legend {
    color: $neutral-500;
    font-weight: 800;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: $slight-shadow-bottom;
    z-index: 2;
  }
  .table-row {
    display: flex;
    background: white;
    border-bottom: 1px solid $neutral-200;

    .values {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      flex-basis: 0;
    }

    &.selected {
      background: $neutral-50;
    }
  }
  .table-cell {
    padding: 16px 8px;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    position: relative;
    &.label {
      max-width: 40px;
      .label-text {
        color: $neutral-500;
      }
      &.selectable {
        cursor: pointer;
      }
    }
    &.content {
      flex-direction: column;
      padding: 0 55px 0 0;
      .inline {
        display: flex;
        column-gap: 5px;
        padding: 16px 8px;
        align-items: center;
      }

      .cell-content-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }
    & .input-wrapper {
      display: flex;
      align-items: flex-start;
      padding: 5px 20px 0 12px;
      min-width: 13px;
      pointer-events: none;
    }
  }
  .arrow {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px 18px 16px;
    cursor: pointer;
    &::v-deep span {
      display: flex;
    }
  }

  .full-name {
    font-weight: 800;
  }
  .relation {
    color: $neutral-500;
    text-transform: capitalize;
  }
  .details {
    display: flex;
    flex-direction: column;
    padding: 0 8px 16px;
  }
  .mother-multiselect {
    min-width: 160px;
    padding: 9px 18px;
  }

  input[type='checkbox'] {
    margin: 0;
    cursor: pointer;
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .compare-relatives-table {
    .table-legend .label {
      .label-text {
        display: none;
      }
    }

    .mother-multiselect {
      padding: 0 0 16px;
    }

    .table-cell.content {
      .cell-content-wrapper {
        flex-direction: column;
      }
      .inline {
        padding: 16px 0 8px;
      }
      .details {
        padding: 0 0 16px;
      }
    }
  }
}
</style>
