<template>
  <div class="tabs-block-item" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Tab Item',
    },
    nameMobile: {
      type: String,
      default: '',
    },
    identifier: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    priority: {
      type: Number,
      default: 100,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.$parent.register(this);
  },
  methods: {
    setIsActive(isActive) {
      this.isActive = isActive;
      if (isActive) {
        const child = this.$children[0];
        if (child && child.hasOwnProperty('activate') && typeof child.activate === 'function') {
          child.activate();
        }
      }
    },
    deactivate() {
      const child = this.$children[0];
      if (child && child.hasOwnProperty('deactivate') && typeof child.deactivate === 'function') {
        child.deactivate();
      }
    },
  },
  name: 'TabsBlockItem',
};
</script>

<style scoped></style>
