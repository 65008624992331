<template>
  <transition name="fade">
    <images-gallery-overlay
      v-if="displayGallery"
      :src="galleryDisplayItemState.src"
      :filename="galleryDisplayItemState.filename"
      :has-next="galleryHasNextItemState"
      :has-previous="galleryHasPreviousItemState"
      :allow-touch="allowTouch"
      :is-info-bar-left-side="isInfoBarLeftSide"
      :init-info-bar-hidden="initInfoBarHidden"
      :imageContainerClasses="imageContainerClasses"
      @click-close="hide"
      @click-next="clickNext"
      @click-previous="clickPrevious"
      @hide-image="$emit('hide-image', $event)"
      @image-loaded="$emit('image-loaded')"
    >
      <slot
        name="info-bar"
        slot="info-bar"
        :item="galleryDisplayItemState"
        :current-index="galleryCurrentIndexState"
        :items-count="galleryItemsCountState"
      ></slot>
      <div slot="image-title" v-if="showFilename" class="image-title">{{ galleryDisplayItemState.filename }}</div>
      <slot name="modals" slot="modals" :item="galleryDisplayItemState"></slot>
    </images-gallery-overlay>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex';

import {isImageFile} from '@/components/common/filePreviewHelper';

import ImagesGalleryOverlay from './imagesGalleryOverlay';

export default {
  props: {
    allowTouch: {type: Boolean, default: true},
    isInfoBarLeftSide: {type: Boolean, default: false},
  },
  watch: {
    galleryDisplayItemState(newItem, oldItem) {
      this.$emit('display-item-changed', newItem);
      this.$store.commit('setBodyForbidScrollState', !!newItem);
    },
  },
  computed: {
    ...mapGetters([
      'galleryDisplayItemState',
      'galleryHasNextItemState',
      'galleryHasPreviousItemState',
      'galleryCurrentIndexState',
      'galleryItemsCountState',
    ]),
    displayGallery() {
      return Boolean(this.galleryDisplayItemState);
    },
    initInfoBarHidden() {
      return this.galleryDisplayItemState.initInfoBarHidden || false;
    },
    isFileImage() {
      return this.galleryDisplayItemState.filename ? isImageFile(this.galleryDisplayItemState.filename) : true;
    },
    showFilename() {
      return !this.isFileImage;
    },
    imageContainerClasses() {
      return {'is-image': this.isFileImage};
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('hideGalleryAction');
      this.$emit('click-close');
    },
    clickNext() {
      this.showImage = false;
      this.$store.commit('setGalleryCurrentIndexState', this.galleryCurrentIndexState + 1);
      this.$emit('click-next', {newItem: this.galleryDisplayItemState});
    },
    clickPrevious() {
      this.showImage = false;
      this.$store.commit('setGalleryCurrentIndexState', this.galleryCurrentIndexState - 1);
      this.$emit('click-previous', {newItem: this.galleryDisplayItemState});
    },
  },
  components: {ImagesGalleryOverlay},
};
</script>

<style lang="scss" scoped></style>
