<template>
  <modal :name="modalName" classes="clear_modal white_modal" :scrollable="true" height="auto">
    <div class="save-data-modal-content with-sticky-buttons">
      <div class="heading">
        <div class="text-lg bold" v-if="isRelativesTableShown">Select relationships to save</div>
        <div class="text-lg bold" v-else>Select the information to save</div>
        <close-icon class="close-icon" @click="closeModal"></close-icon>
      </div>

      <progress-bar :value="progressValue" v-if="progressValue"></progress-bar>

      <div class="body">
        <div class="person-data-container">
          <div class="profile-picture-container">
            <img
              :src="person.profile_picture.attachment"
              class="profile-picture"
              v-if="person.profile_picture && person.profile_picture.attachment"
            />
            <person-icon-colored v-else :gender="person.gender"></person-icon-colored>
          </div>
          <div class="name-container">
            <div class="bold text-lg text-lg-mobile">{{ person.full_name }}</div>
            <div class="living-status text-sm text-sm-mobile">{{ livingStatus }}</div>
          </div>
        </div>

        <compare-relatives-table
          v-show="isRelativesTableShown"
          :relatives-table="relativesTable"
          :person-relatives="personRelatives"
          ref="relatives"
        ></compare-relatives-table>
        <compare-persons-table
          :rows="comparisonTable"
          ref="table"
          :save-mode="true"
          v-show="!isRelativesTableShown"
        ></compare-persons-table>
      </div>

      <div class="action-buttons right-align">
        <mcr-button class="white" v-if="showCancel" @click="closeModal">Cancel</mcr-button>
        <mcr-button class="white" v-if="showBack" @click="goBack"
          ><arrow-left-icon :size="18" /><span>Back</span></mcr-button
        >
        <mcr-button class="save" v-if="showSave" @click="onSave">Save Information</mcr-button>
        <mcr-button class="save" v-else-if="showNext" @click="goNext">Next</mcr-button>
        <mcr-button class="save" v-else-if="showAddPersons" @click="onSave">Add Persons</mcr-button>
      </div>
    </div>
  </modal>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import ProgressBar from '@common/elements/layouts/ProgressBar';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getLifetimeYears} from '@common/utils/utils';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft';
import CloseIcon from 'vue-material-design-icons/Close';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import ComparePersonsTable from '@/components/common/tree/ComparePersonsTable';
import CompareRelativesTable from '@/components/common/tree/CompareRelativesTable';

export default {
  props: {
    person: Object,
    comparisonTable: Array,
    relativesTable: Array,
    personRelatives: Array,
  },
  data() {
    return {
      modalName: 'save-data-modal',
      isRelativesTableShown: !this.comparisonTable.length && this.relativesTable.length,
    };
  },
  computed: {
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    showSave() {
      return this.comparisonTable.length && !this.relativesTable.length;
    },
    showNext() {
      return this.comparisonTable.length && this.relativesTable.length && !this.isRelativesTableShown;
    },
    showAddPersons() {
      return this.isRelativesTableShown;
    },
    showCancel() {
      return this.comparisonTable.length && !this.isRelativesTableShown;
    },
    showBack() {
      return this.comparisonTable.length && this.isRelativesTableShown;
    },
    progressValue() {
      if (this.relativesTable.length && this.comparisonTable.length) {
        return this.isRelativesTableShown ? 100 : 50;
      }
      return 0;
    },
  },
  methods: {
    closeModal() {
      AnalyticsAmplitudeHandler.trackHintSaveDataClickBackEvent();
      this.$modal.hide(this.modalName);
    },
    onSave() {
      this.$modal.hide(this.modalName);
      const personData = this.$refs.table.getDataForSave();
      const relativesData = this.$refs.relatives.getDataForSave();
      this.$emit('save', {personData, relativesData});
    },
    goNext() {
      this.isRelativesTableShown = true;
    },
    goBack() {
      this.isRelativesTableShown = false;
    },
  },
  components: {
    CompareRelativesTable,
    PersonIconColored,
    ComparePersonsTable,
    CloseIcon,
    McrButton,
    ProgressBar,
    ArrowLeftIcon,
  },
  name: 'SaveDataModal',
};
</script>

<style lang="scss" scoped>
.save-data-modal-content {
  width: $reading-max-width;
  .person-data-container {
    display: flex;
    column-gap: 12px;
    align-items: center;
    padding: 24px;
  }
  .living-status {
    color: $neutral-500;
  }
  .profile-picture-container {
    border-radius: 50%;
    overflow: hidden;
    width: 44px;
    height: 44px;
    flex-shrink: 0;

    &::v-deep svg {
      width: 30px;
      height: 30px;
    }
  }

  .comparison-table,
  .compare-relatives-table {
    margin: 0 24px 16px;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    .comparison-table,
    .compare-relatives-table {
      margin: 0 0 16px;
    }
  }
}
</style>
