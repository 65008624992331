<template>
  <images-gallery-overlay-container @image-loaded="onImageLoad" @display-item-changed="onDisplayItemChanged">
    <template v-slot:info-bar="props">
      <div class="info-bar-content">
        <h5>Images of {{ personName }}</h5>
        <p class="photo-count">{{ props.currentIndex + 1 }} of {{ totalCount }} images</p>
        <template v-if="props.item.priority">
          <div class="source-name">{{ sourceName }} (Page {{ props.item.priority }})</div>
          <div class="info-bar-actions">
            <router-link :to="getViewerRoute(props.item.priority, props.item.id)" class="make-profile-picture"
              ><view-icon></view-icon> Open Source in Viewer</router-link
            >
          </div>
        </template>
      </div>
    </template>
  </images-gallery-overlay-container>
</template>

<script>
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import {preload} from '@common/utils/utils.sources';
import ViewIcon from 'vue-material-design-icons/FolderMoveOutline';
import {mapGetters} from 'vuex';

export default {
  props: {
    getNextPage: Function,
  },
  computed: {
    ...mapGetters(['galleryMetaState']),
    personName() {
      return this.galleryMetaState.personName;
    },
    sourceName() {
      return this.galleryMetaState.sourceName;
    },
    totalCount() {
      return this.galleryMetaState.totalCount;
    },
  },
  methods: {
    onDisplayItemChanged(newItem) {
      if (newItem.isLoading) {
        this.getNextPage();
      }
    },
    onImageLoad() {
      if (this.$store.getters.galleryHasNextItemState) {
        const src = this.$store.getters.galleryItemsState[this.$store.getters.galleryCurrentIndexState + 1].src;
        preload([src]);
      }
    },
    getViewerRoute(priority, id) {
      return this.$store.getters.galleryMetaState.getViewerRoute(priority, id);
    },
  },
  name: 'MentionsImagesGalleryOverlayContainer',
  components: {ImagesGalleryOverlayContainer, ViewIcon},
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';
.source-name {
  word-break: break-word;
}
</style>
