<template>
  <div class="person-icon-colored" :class="classes">
    <person-icon :gender="gender"></person-icon>
  </div>
</template>

<script>
import PersonIcon from '@/base/icons/person/PersonIcon';

export default {
  props: {
    gender: String, // 'm' or 'f'
    colored: {type: Boolean, default: true},
  },
  computed: {
    classes() {
      return this.colored ? [this.gender, 'colored'] : [this.gender];
    },
  },
  components: {PersonIcon},
};
</script>

<style lang="scss" scoped>
.person-icon-colored {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  &::v-deep .person-icon {
    transition: all 0.1s ease;
    .shape,
    .symbol {
      transition: all 0.1s ease;
      fill-opacity: 1;
    }
  }
  svg {
    width: 110px;
    height: 110px;
  }

  background-color: rgba(lightgrey, 0.3);

  .person-icon::v-deep {
    .shape {
      opacity: 0.12;
      stroke: black;
      fill: black;
    }
    .symbol {
      fill: black;
      opacity: 0;
    }
  }

  &.colored.m {
    background-color: $male-background-color;
    .person-icon::v-deep {
      .shape {
        opacity: 1;
        stroke: powderblue;
        fill: powderblue;
      }
    }
  }
  &.colored.f {
    background-color: $female-background-color;
    .person-icon::v-deep {
      .shape {
        opacity: 1;
        stroke: pink;
        fill: pink;
      }
    }
  }
}
</style>
